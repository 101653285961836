import './App.css';
import { SlInput } from "@shoelace-style/shoelace/dist/react";
//light theme stylesheet
import "@shoelace-style/shoelace/dist/themes/light.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Graydon House Cafe a
        </p>
        <SlInput value={""} label="Name" />
      </header>
    </div>
  );
}

export default App;
